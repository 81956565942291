import * as yup from 'yup';

export const loginValidationSchema = yup.object({
  email: yup.string().email().required('Email is required'),
  password: yup
    .string()
    .required('Password must be of at least 5 characters')
    .min(5),
});

export const signUpValidationSchema = yup.object({
  email: yup.string().email().required('Email is required'),
  password: yup.string().required('Password is required').min(5),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
});

export const updateAccountValidationSchema = yup.object({
  email: yup.string().email().required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
});

export const updatePasswordValidationSchema = yup.object({
  password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  password2: yup.string()
    .test('passwords-must-match', "Passwords must match", function (password2) {
      const { password } = this.parent;

      return password === password2;
    })
});

export const forgotPasswordValidationSchema = yup.object({
  email: yup.string().email().required('Email is required'),
});

export const cardValidationSchema = yup.object({
  name: yup.string().required('Name is required')
});

export const cardUpdateValidationSchema = yup.object({
  expMonth: yup.string().required(),
  expYear: yup.string().required()
})
