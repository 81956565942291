import * as yup from 'yup';

export const paymentValidationSchema = yup.object().shape({
  billingName: yup.string().required('Billing name is required'),
  address1: yup.string().required('Address line 1 is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  district: yup.string().required('District is required'),
  country: yup.string().required('Country is required'),
  zip: yup.string().required('Zip is required'),
  number: yup.string().required('Card number is required'),
  expiry: yup.string().required('Card expiration date is required'),
  cvc: yup.number().required('CVC number is required'),
  name: yup.string().required('Card holder name is required'),
});

export const addressValidationSchema = yup.object().shape({
  billingName: yup.string().required('Billing name is required'),
  address1: yup.string().required('Address line 1 is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  zip: yup.string().required('Zip is required'),
});
