import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import classes from '@/styles/login.module.css';
import clsx from 'clsx';
import { Button } from '@/components/ui/button';
import { ILoginPayload } from '@/types';
import { Input } from '@/components/ui';
import { LoadingScreen } from '@/components/layout/loading';
import { LogoWhite, EyeIcon } from '@/icons';
import { login, userSelector } from '@/redux/slices/userSlice';
import { loginValidationSchema } from '@/utils/validation-schema';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';

const LoginPage: NextPage = () => {
  const router = useRouter();
  const [passwordType, setPasswordType] = useState('password');
  const { isFailed, isLoggedIn, isLoading, roles } = useSelector(userSelector);
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setPasswordType(prev => (prev === 'text' ? 'password' : 'text'));
  };

  const {
    errors,
    handleChange,
    handleSubmit,
    values,
  } = useFormik<ILoginPayload>({
    initialValues: { email: '', password: '' },
    validationSchema: loginValidationSchema,
    onSubmit: async (values, helper) => {
      dispatch(login({
        email: values.email,
        password: values.password,
      }))
    },
  });

  useEffect(() => {
    if (!isLoading && isFailed) {
      toast('Email or password not found', { type: 'error' });
    }

    if (!isLoading && isLoggedIn) {
      let route = '/dashboard/cars';
      toast('Logged in!', { type: 'success' });

      for (let el of roles) {
        if (el.name !== 'member') {
          route = `/${el.name}`;
          break;
        }
      }

      router.push(route);
    }
  }, [isLoading, isFailed, isLoggedIn, roles, router]);

  if (isLoading || isLoggedIn) {
    return <LoadingScreen />
  }

  return (
    <>
      <Head>
        <title>Rynse | Log in</title>
      </Head>
      <main
        className={clsx(
          classes.container,
          'flex flex-col flex-1 items-center w-screen min-h-screen'
        )}
      >
        <div className="z-10 flex flex-col flex-1 mt-24 md:min-w-128 md:max-w-2xl">
          <div className="items-center text-center">
            <Link href="https://gorynse.com">
              <a className="text-center" target="_blank">
                <LogoWhite className="items-center h-12 text-white cursor-pointer fill-current" />
              </a>
            </Link>
          </div>

          <div className="p-8 my-10 bg-white shadow-lg rounded-xl md:p-12">
            <h3
              className={clsx(
                classes['login-title'],
                'relative text-3xl font-semibold text-gray-800'
              )}
            >
              Login
            </h3>
            <form className="mt-6 mb-4 space-y-4" onSubmit={handleSubmit}>
              <Input
                placeholder="Email Address *"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
                disabled={isLoading}
              />
              <Input
                placeholder="Password *"
                name="password"
                type={passwordType}
                value={values.password}
                onChange={handleChange}
                rightIcon={
                  <EyeIcon
                    className="mr-2.5 w-5 h-5 text-gray-400 hover:text-gray-600 cursor-pointer fill-current"
                    onClick={togglePasswordVisibility}
                  />
                }
                error={errors.password}
                disabled={isLoading}
              />

              <div className="flex justify-end flex-1">
                <Link href="/forgot-password">
                  <a className="text-base font-medium text-right text-primary-500 hover:underline">
                    Forgot Password?
                  </a>
                </Link>
              </div>
              <Button
                type="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                LOGIN
              </Button>
            </form>

            <div className="flex items-center justify-center flex-1">
              <span className="inline-block mr-1 text-gray-400">
                Don&apos;t have an account?{' '}
              </span>
              <Link href="/sign-up">
                <a className="text-base text-right text-primary-500 hover:underline">
                  Sign up
                </a>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LoginPage;
